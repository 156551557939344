/* eslint-disable import/no-cycle */
import cx from 'classnames';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import './CloudTvBlock.scss';
import { useCurrentCloudTvSession } from './cloudtv.hooks';

import { useTracking } from '../../../Context';
import { LayoutContext } from '../../../components/GridLayout/GridLayout';
import { eventTags } from '../../../core/trackers/events';
import { replaceValues } from '../../../utils/stringUtils';
import FeedbackPopupBlock from '../../../workshops/blocks/FeedbackPopupBlock';
import WorkshopLiveBlock from '../../../workshops/blocks/WorkshopLiveBlock';

import CloudTvActions from './components/CloudTvActions';
import CloudTvLiveDescription from './components/CloudTvLiveDescription';

const translationPrefix = 'blocks.cloudtv';

const CloudTvBlock = ({
  _id,
  title,
  container,
  countdown,
  videoProps,
  workshopFilter,
  mode,
  forceLiveStream,
  ...rest
}) => {
  const { showCurrentLive, liveDescriptionConfig = {}, actions = 0, collection } = rest;
  const { allowFullScreen } = videoProps;
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const [showDescription, setShowDescription] = useState(false);
  const { upcomingLive, hasCurrentLive } = useCurrentCloudTvSession(mode, {
    forceLiveStream,
    workshopFilter,
    collection,
  });
  const { layout, setLayout } = useContext(LayoutContext);

  if (!upcomingLive) {
    // Keep current feedback open
    return (
      <>
        <FeedbackPopupBlock item={{}} />
      </>
    );
  }

  const handleAction = (action) => {
    switch (action.type) {
      case 'info': {
        trackEvent(eventTags.ITEM_ACTION_CLICK, { item: upcomingLive, action });
        setShowDescription(!showDescription);
        return action.type;
      }
      case 'fullscreen': {
        setLayout(layout === 'default' ? 'wide' : 'default');
        return action.type;
      }
      default:
        return noop;
    }
  };

  return (
    <>
      <FeedbackPopupBlock item={upcomingLive} />
      <div className={cx('block--cloudtv', _id)}>
        {showCurrentLive && hasCurrentLive && (
          <div className="currentLive">
            <span className="live">{t(`${translationPrefix}.live`)}</span>{' '}
            <span className="watch-live">{t(`${translationPrefix}.watch-live`)}</span>
            <span className="title">{upcomingLive.title}</span>
          </div>
        )}
        <WorkshopLiveBlock
          defaultActive
          countdown={countdown}
          allowFullScreen={allowFullScreen}
          item={upcomingLive}
          containerType={container?.type}
          title={replaceValues(container?.header || title, { item: upcomingLive })}
          videoProps={videoProps}
        />
        <CloudTvActions actions={actions} session={upcomingLive} onClick={handleAction} />
        <CloudTvLiveDescription
          isOpen={showDescription}
          onClose={() => setShowDescription(false)}
          session={upcomingLive}
          {...liveDescriptionConfig}
        />
      </div>
    </>
  );
};

CloudTvBlock.defaultProps = {
  container: undefined,
  countdown: undefined,
  forceLiveStream: undefined,
  mode: 'agenda',
  showComments: false,
  showCurrentLive: false,
  title: undefined,
  videoProps: {},
  workshopFilter: {},
};

CloudTvBlock.propTypes = {
  _id: PropTypes.string.isRequired,
  container: PropTypes.object,
  countdown: PropTypes.object,
  forceLiveStream: PropTypes.object,
  mode: PropTypes.string,
  showComments: PropTypes.bool,
  showCurrentLive: PropTypes.bool,
  title: PropTypes.string,
  videoProps: PropTypes.shape({
    allowFullScreen: PropTypes.bool,
  }),
  workshopFilter: PropTypes.object,
};

export default connect((state) => ({
  workshops: state.workshops.workshops,
}))(CloudTvBlock);
