/* eslint-disable operator-linebreak */
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';

import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import WorkshopList from './WorkshopList';

import BlockContainer from '../BlockContainer';

import { useConfig } from '../../config/config.context';
import { useMe } from '../../profile/hooks';
import WorkshopTable from '../../workshops/components/WorkshopTable/WorkshopTable';

import TimezoneTabs from '../TimezoneTabs/TimezoneTabs';

const translationPrefix = 'blocks';

const components = {
  grid: WorkshopList,
  table: WorkshopTable,
};

const WorkshopSessionsBlock = ({ sessions, itemProps, template, ...rest }) => {
  const { label, mode, groupBy, container } = rest || {};
  const { t } = useTranslation();
  const user = useMe();
  const { timezones, defaultTimezone } = useConfig();
  const userTimezone = get(user, 'timezone', defaultTimezone);
  const selectedTimezone = userTimezone || timezones?.[0]?.key;

  if (!sessions || sessions.length === 0) return null;
  const filteredSessionsByTimezone =
    timezones?.length > 0
      ? sessions.filter((session) => session?.timezone === selectedTimezone)
      : sessions;
  const orderedSessions = orderBy(filteredSessionsByTimezone, 'startDate', 'asc');
  const WorkshopsComponent = get(components, mode, WorkshopList);
  return (
    <BlockContainer {...container} className="sessions-block">
      <Header as="h3">{label || t(`${translationPrefix}.sessions`)}</Header>
      <TimezoneTabs />
      <WorkshopsComponent
        workshopList={orderedSessions}
        groupBy={groupBy}
        template={template}
        itemProps={itemProps}
        timezone={selectedTimezone}
      />
    </BlockContainer>
  );
};

WorkshopSessionsBlock.defaultProps = {
  header: undefined,
  headerKey: undefined,
  itemProps: undefined,
  template: undefined,
};

WorkshopSessionsBlock.propTypes = {
  sessions: PropTypes.array.isRequired,
  header: PropTypes.string,
  headerKey: PropTypes.string,
  itemProps: PropTypes.object,
  template: PropTypes.string,
};

export default connect((state, { item }) => ({
  sessions: state.workshopSessions.workshopSessions?.filter(({ groupId }) => item?._id === groupId),
}))(WorkshopSessionsBlock);
