/* eslint-disable operator-linebreak */
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import { useTracking } from '../../Context';
import { userAgenda } from '../../agenda/store/agenda.selectors';
import { eventTags } from '../../core/trackers/events';
import { workshopProptypes } from '../../propTypes';
import { useWorkshopRegistration } from '../utils/workshopRegistrationUtils';
import { useWorkshopSessionRegistration } from '../../workshop-session/store/workshopSessions.hooks';
import { useIsAnonymous } from '../../profile/hooks';
import { urlJoin } from '../../utils/stringUtils';
import { useEntityPath } from '../../components/EntityLink';
import store from '../../shared/Store';
import ShareSocialNetworkButton from '../../components/ShareSocialNetwork';

export function useActionRegistration(workshop) {
  const isAnonymousUser = useIsAnonymous();
  const agenda = useSelector(userAgenda);
  const { registerWorkshop, unregisterWorkshop } = useWorkshopRegistration(workshop);
  const { registerWorkshopSession, unregisterWorkshopSession } = useWorkshopSessionRegistration(
    workshop,
  );

  const registration = agenda.find((a) => a._id === workshop?._id);
  return {
    isAnonymousUser,
    registerWorkshop,
    unregisterWorkshop,
    registerWorkshopSession,
    unregisterWorkshopSession,
    registration,
  };
}

export function getActionOptions(action, registration) {
  const { key, label, icon } = action;
  switch (key) {
    case 'save': {
      if (!registration) return { label: 'Save', icon: 'star outline', type: 'save' };
      return { label: 'Unsave', icon: 'star', type: 'unsave' };
    }
    case 'replay':
    case 'share':
    default:
      return { icon, label };
  }
}

const ActionButton = ({ action, onClick }) => {
  const { icon, label, style, size, className } = action;
  return (
    <Button
      size={size}
      className={cx('action', className)}
      onClick={(e) => onClick(e, action)}
      labelPosition="right"
      style={style}
      icon
    >
      {label}
      <Icon name={icon} style={{ backgroundColor: 'transparent' }} />
    </Button>
  );
};

ActionButton.defaultProps = {
  className: '',
};

ActionButton.propTypes = {
  className: PropTypes.string,
  action: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

function computeEntityUrl(path) {
  if (!path) return null;
  const { origin, pathname } = window.location;
  if (path[0] === '?') {
    // Query string, use current path
    return urlJoin([origin, pathname]) + path;
  }
  return urlJoin([origin, window.__DATA__.basename, path]);
}

export const EntityShareButton = ({ action, entity = {}, onClick }) => {
  const { trackEvent } = useTracking();

  const { title } = entity;
  const { path } = useEntityPath({ kind: 'workshops', ...entity });

  const shareUrl = computeEntityUrl(path);

  if (!shareUrl) return null;

  const { className, icon = 'share', itemProps = {}, label = 'Share', size, style } = action;
  const { iconSize, hashtag, hashtags } = itemProps;

  const handleClick = (e) => {
    onClick(e, action);
  };

  const handleShare = (type) => {
    trackEvent(eventTags.SHARE_SOCIAL_CLICK, {
      userId: store.userId,
      item: entity,
      kind: 'workshops',
      action: { type: 'share' },
      entityType: 'shareSocialNetwork',
      sharingType: type,
    });
  };

  return (
    <ShareSocialNetworkButton
      action={action}
      size={size || 'small'}
      className={className}
      buttonIcon={icon}
      buttonLabel={label}
      iconSize={iconSize}
      hashtag={hashtag}
      hashtags={hashtags}
      onClick={handleClick}
      onShare={handleShare}
      shareTitle={title}
      shareUrl={shareUrl}
      style={style}
    />
  );
};

const WorkshopActions = ({ workshop, actions, onClick }) => {
  const { trackEvent } = useTracking();
  const {
    isAnonymousUser,
    registerWorkshop,
    unregisterWorkshop,
    registerWorkshopSession,
    unregisterWorkshopSession,
    registration,
  } = useActionRegistration(workshop);

  if (!actions || actions.length === 0) return null;

  const handleAction = (e, action) => {
    const formattedAction = { ...action, ...getActionOptions(action, registration) };
    e.preventDefault();
    e.stopPropagation();

    switch (action.type) {
      case 'save': {
        if (isAnonymousUser) {
          onClick(true);
        } else if (!registration) {
          if (workshop.workshopId) registerWorkshopSession();
          else registerWorkshop();
        }
        return action.type;
      }
      case 'unsave': {
        if (isAnonymousUser) {
          onClick(true);
        } else if (workshop.workshopId) unregisterWorkshopSession();
        else unregisterWorkshop();
        return action.type;
      }
      case 'share':
      case 'replay': {
        trackEvent(eventTags.ITEM_ACTION_CLICK, { item: workshop, action: formattedAction });
        return action.type;
      }
      default:
        return noop;
    }
  };
  return (
    <div className="actions">
      {actions.map((action) => {
        const { key } = action;
        if (key === 'save' && !workshop) return null;
        if (key === 'share')
          return (
            <EntityShareButton key={key} action={action} onClick={handleAction} entity={workshop} />
          );
        return (
          <ActionButton
            key={key}
            action={{ ...action, ...getActionOptions(action, registration) }}
            onClick={handleAction}
          />
        );
      })}
    </div>
  );
};

WorkshopActions.defaultProps = {
  actions: [],
};

WorkshopActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func.isRequired,
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopActions;
