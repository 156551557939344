import { useEffect, useState } from 'react';
import platformService from '../../core/services/platform.service';

const initialState = { contacts: [], totalCount: 0 };
const pageStartIndex = 1;

const useContactSearch = (filters) => {
  const [response, setResponse] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(pageStartIndex);
  const [error, setError] = useState(null);
  const fetchParticipants = (currentFilter, currentPage = pageStartIndex) => {
    let canceled = false;
    setLoading(true);
    setError(null);
    platformService
      .searchNetworkingParticipants(currentFilter, currentPage)
      .then((newData) => {
        if (canceled) return;
        if (!newData || !newData.contacts) return;
        setResponse((old) => {
          const contacts = old.contacts.concat(newData.contacts);
          return {
            contacts,
            totalCount: newData.totalCount,
          };
        });
      })
      .catch((e) => {
        if (canceled) return;
        setError(e);
      })
      .finally(() => {
        if (canceled) return;
        setLoading(false);
      });

    return () => {
      canceled = true;
    };
  };
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let cancel = () => {};
    const timeoutId = setTimeout(() => {
      setResponse(initialState);
      setPage(pageStartIndex);
      cancel = fetchParticipants(filters);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
      cancel();
    };
  }, [filters]);
  useEffect(() => {
    if (page > pageStartIndex) {
      return fetchParticipants(filters, page);
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [page]);
  return {
    contacts: response.contacts,
    totalCount: response.totalCount,
    currentPage: page,
    loading,
    error,
    lastPage: response.contacts.length >= response.totalCount,
    next: () => setPage((p) => p + 1),
  };
};
export default useContactSearch;
